// extracted by mini-css-extract-plugin
export var button = "PlasmicFooter-module--button--3cfDF";
export var freeBox___3Ht0W = "PlasmicFooter-module--freeBox___3Ht0W--t2ytJ";
export var freeBox___9I5V = "PlasmicFooter-module--freeBox___9I5V--2mR8+";
export var freeBox__azitj = "PlasmicFooter-module--freeBox__azitj--qmwLZ";
export var freeBox__cb9K5 = "PlasmicFooter-module--freeBox__cb9K5--qe6S-";
export var freeBox__fFeCh = "PlasmicFooter-module--freeBox__fFeCh--YG-UN";
export var freeBox__gJkTt = "PlasmicFooter-module--freeBox__gJkTt--eiKn0";
export var freeBox__hBka2 = "PlasmicFooter-module--freeBox__hBka2--+209Q";
export var freeBox__msMuC = "PlasmicFooter-module--freeBox__msMuC--0pooj";
export var freeBox__nH5CX = "PlasmicFooter-module--freeBox__nH5CX--5v5BY";
export var freeBox__oIr2D = "PlasmicFooter-module--freeBox__oIr2D--tlbMY";
export var freeBox__ooNwX = "PlasmicFooter-module--freeBox__ooNwX--NkL4L";
export var freeBox__tAE5 = "PlasmicFooter-module--freeBox__tAE5--lT6mI";
export var freeBox__uz0W8 = "PlasmicFooter-module--freeBox__uz0W8--91gjN";
export var iconLink__iWgbY = "PlasmicFooter-module--iconLink__iWgbY--L-gc7";
export var iconLink__m4CWy = "PlasmicFooter-module--iconLink__m4CWy--LGXmY";
export var iconLink__rBpNo = "PlasmicFooter-module--iconLink__rBpNo--qDgfc";
export var iconLink__uwrG3 = "PlasmicFooter-module--iconLink__uwrG3--qQZaP";
export var link___3Q9Af = "PlasmicFooter-module--link___3Q9Af--C5vGe";
export var link___7KiIw = "PlasmicFooter-module--link___7KiIw--mJsuR";
export var link__aYuuc = "PlasmicFooter-module--link__aYuuc--D5rhb";
export var link__baezt = "PlasmicFooter-module--link__baezt--eho-7";
export var link__c0Vj6 = "PlasmicFooter-module--link__c0Vj6--05hGx";
export var link__cnHc = "PlasmicFooter-module--link__cnHc--CbK7k";
export var link__dqwI = "PlasmicFooter-module--link__dqwI--BnhZw";
export var link__eFe4M = "PlasmicFooter-module--link__eFe4M--kknVj";
export var link__evSSd = "PlasmicFooter-module--link__evSSd--1Xaar";
export var link__jNzPc = "PlasmicFooter-module--link__jNzPc--HnPOr";
export var link__jXakt = "PlasmicFooter-module--link__jXakt--PiibC";
export var link__knZmw = "PlasmicFooter-module--link__knZmw--Akvws";
export var link__nbTsL = "PlasmicFooter-module--link__nbTsL--TGCBP";
export var link__oe7Sl = "PlasmicFooter-module--link__oe7Sl--qTUNE";
export var link__q8M7W = "PlasmicFooter-module--link__q8M7W--Yvbl8";
export var link__qsp6I = "PlasmicFooter-module--link__qsp6I--7JWF+";
export var link__tPncb = "PlasmicFooter-module--link__tPncb---YxBt";
export var link__xairv = "PlasmicFooter-module--link__xairv--z3XRd";
export var link__zmgL = "PlasmicFooter-module--link__zmgL--DwR02";
export var root = "PlasmicFooter-module--root--WcEx6";
export var svg___0WFb1 = "PlasmicFooter-module--svg___0WFb1--Kjac7";
export var svg___4Ki = "PlasmicFooter-module--svg___4Ki--I-h3y";
export var svg__bzFev = "PlasmicFooter-module--svg__bzFev--btMX0";
export var svg__ea8Oz = "PlasmicFooter-module--svg__ea8Oz--aswPc";
export var svg__fwkqH = "PlasmicFooter-module--svg__fwkqH--G66F-";
export var svg__iWvRm = "PlasmicFooter-module--svg__iWvRm--TmqpJ";
export var svg__ljQub = "PlasmicFooter-module--svg__ljQub--NrCxq";
export var svg__tUg2K = "PlasmicFooter-module--svg__tUg2K--zKzEs";
export var textInput = "PlasmicFooter-module--textInput--UnyrH";
export var text___9MGVq = "PlasmicFooter-module--text___9MGVq--vrrxM";
export var text__eHrhb = "PlasmicFooter-module--text__eHrhb--NTMbV";
export var text__efBpa = "PlasmicFooter-module--text__efBpa--4YgK6";
export var text__eo0BJ = "PlasmicFooter-module--text__eo0BJ--X9jm0";
export var text__hDtp = "PlasmicFooter-module--text__hDtp--X3tcd";
export var text__prhjW = "PlasmicFooter-module--text__prhjW--F-a7a";
export var text__xElY = "PlasmicFooter-module--text__xElY--AZ6Py";